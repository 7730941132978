<template>
  <v-app>
    <v-container data-app>
      <v-card>
        <v-card-title>Наставники</v-card-title>
        <v-card-actions class="py-0">
          <v-col class="col-7 col-md-2">
            <label>Период</label>
            <v-menu
                ref="menu"
                transition="scale-transition"
                right
                :close-on-content-click="false"
                bottom
                nudge-bottom="35"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    outlined
                    :value="$root.outputRange(filters.date)"
                    readonly
                    v-bind="attrs"
                    style="width: 100%"
                    v-on="on"
                />
                <v-btn
                    icon
                    @click.stop="filters.date=[]"
                    color="grey"
                    :style="filters.date.length ? '' : 'display: none'"
                    style="position: absolute;right: 5px;top: 28px;z-index: 100;"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </template>
              <v-date-picker
                  v-model="filters.date"
                  no-title
                  scrollable
                  @change="loadTable"
                  range
                  first-day-of-week="1"
                  locale="ru"
              />
            </v-menu>
          </v-col>
        </v-card-actions>
        <v-card-text>
          <v-data-table
              :headers="headers"
              :items="rows"
              item-key="n_id"
              :loading="loadingTable"
              :footer-props="{'items-per-page-options':[-1]}"
              no-data-text="Данных нет"
              loading-text="Загрузка..."
              mobile-breakpoint="0"
          >
            <template v-slot:item="{item, index}">
              <tr style="cursor: pointer" @click="$eventBus.$emit('openCleaner', item.n_id)">
                <td style="white-space: nowrap">
                  {{ index + 1 }}
                  <v-icon v-if="!item.photo" color="red" size="16">mdi-camera</v-icon>
                </td>
                <td>{{ $root.cleanerInitials(item.name) }}</td>
                <td style="max-width: 150px">{{ item.comment }}</td>
                <td v-on:click.stop><a :href="'tel: '+item.phone" style="white-space: nowrap">{{ item.phone }}</a></td>
                <td v-for="field in ['newCleaners', 'firstSalary', 'secondSalary']">
                  <ol>
                    <li v-for="cleaner in item[field]">
                      <a @click.stop="$eventBus.$emit('openCleaner', cleaner.n_id)">{{$root.cleanerInitials(cleaner.name)}}</a>
                    </li>
                  </ol>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-container>
  </v-app>
</template>

<script>
export default {
  name: "CleanerMentor",
  data() {
    return {
      loadingTable: true,
      rows: [],
      filters: {date: [this.$moment().startOf('month').format('YYYY-MM-DD'), this.$moment().endOf('month').format('YYYY-MM-DD')]},
      headers: [
        {text: '№', value: '',},
        {text: 'ФИО', value: 'name'},
        {text: 'Примечание', value: 'comment'},
        {text: 'Телефон', value: 'phone'},
        {text: 'Отстажировалось', value: 'newCleaners'},
        {text: 'Получили 1 зп', value: 'firstSalary'},
        {text: 'Получили 2 зп', value: 'secondSalary'},
      ],
    }
  },
  methods: {
    loadTable(clearTable = true) {
      if (clearTable === true) {
        this.loadingCleaners = true;
        this.cleaners = [];
      }
      console.log('request', this.filters);
      this.$store.state.server.request('cleanerMentor/get', this.filters, (data) => {
        this.rows = data.response;
        this.loadingTable = false;
        if (!clearTable) setTimeout(() => {
          if (this.$router.currentRoute.path === '/cleanerMentor') this.loadTable(false)
        }, 5000)
      }, () => {
        this.loadingTable = false
        if (!clearTable) setTimeout(() => {
          if (this.$router.currentRoute.path === '/cleanerMentor') this.loadTable(false)
        }, 5000)
      });
    },
  },
  mounted() {
    this.loadTable(false)
  },
}
</script>

<style scoped>

</style>